import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { blobToText } from '@shared/utils/functions';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderAndErrorService } from './../services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private loaderAndErrorService: LoaderAndErrorService,
    private translocoService: TranslocoService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const toastrService = this.injector.get(ToastrService);
          let errorMessage = '';
          let isBlobResponse = false;

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else if (error.error instanceof Blob) {
            // server-side error
            isBlobResponse = true;
            blobToText(error?.error).then((text) => {
              const parsedText = JSON.parse(text);
              errorMessage = parsedText?.title || parsedText?.detail || this.translocoService.translate('HTTP_ERROR_MESSAGES.DEFAULT');
            });
          } else {
            // server-side error
            switch (error.status) {
              case 401:
                // console.log('UnAuthorized error', error);
                // errorMessage = `You are Unauthorized to perform this action`;
                errorMessage = this.translocoService.translate('HTTP_ERROR_MESSAGES.401');
                break;
              case 400:
                if (typeof error.error === 'string') {
                  const parsedError = JSON.parse(error.error)
                  errorMessage = parsedError?.message || parsedError?.title;
                } else {
                  errorMessage = error.error.message || error.error.title;
                }
                break;
              case 404:
                // errorMessage = `The requested resource could not be found`;
                errorMessage = this.translocoService.translate('HTTP_ERROR_MESSAGES.404');
                break;
              default:
                // console.log('Default Error', error);
                // errorMessage = `An error occurred. We are looking into it!`;
                errorMessage = this.translocoService.translate('HTTP_ERROR_MESSAGES.DEFAULT');
                break;
            }
          }
          setTimeout(() => {
            toastrService.error(errorMessage);
            this.loaderAndErrorService.setError(errorMessage);
          }, isBlobResponse ? 500 : 0);
          return throwError(error);
          // return of(new HttpResponse({}));
          // this._loading.setError(errorMessage);
        })
      );
  }
}
